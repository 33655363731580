<template>
    <div class="putInto">
        <van-cell title="检查类型" :value="typeText" @click="showState=true"/>
        <van-popup v-model="showState" round position="bottom">
            <van-picker title="选择类型" show-toolbar
                        :columns="typeList"
                        @cancel="showState = false"
                        @confirm="changeStateName"
            />
        </van-popup>

        <div v-if="checkType===1">
            <van-field v-model="stationTxt" label="站点名称" placeholder="请输入"/>
            <van-button type="primary" size="small" color="#3F7C53" @click="searchKeyword">查询</van-button>

        </div>
        <van-popup v-model="showkindList" round position="bottom">
            <van-picker title="选择名称" show-toolbar
                        :columns="kindList"
                        @cancel="showkindList = false"
                        @confirm="onConfirmName"
            />
        </van-popup>
        <div v-if="checkType===2">
            <van-field v-model="marketName" label="运营人员" placeholder="请输入"/>
            <van-button type="primary" size="small" color="#3F7C53" @click="searchMarket">查询</van-button>

        </div>
        <van-popup v-model="showMarketList" round position="bottom">
            <van-picker title="选择人员" show-toolbar
                        :columns="marketList"
                        @cancel="showMarketList = false"
                        @confirm="onConfirmUserName"
            />
        </van-popup>


        <div class="qc-info">
            <div class="line15"></div>
            <div class="qc-content">
                <div v-if="checkType===1" class="qc-content__title">站点规范</div>
                <div v-if="checkType===1" v-for="(item, index) in stationCheckingList" :key="index">
                    <div class="qc-content__item  flex">
                        <div class="qc-left">{{ item.dictValue }}</div>
                        <div class="qc-right">
                            <van-radio-group checked-color="#3F7C53"
                                             class="radiogroup"
                                             direction="horizontal"
                                             v-model="item.checkValue">
                                <van-radio name="1">规范
                                    <template #icon="props">
                                        <img class="img-icon" :src="props.checked ? checked : unchecked"/>
                                    </template>
                                </van-radio>
                                <van-radio name="0">不规范
                                    <template #icon="props">
                                        <img class="img-icon" :src="props.checked ? checked : unchecked"/>
                                    </template>
                                </van-radio>
                            </van-radio-group>
                        </div>
                    </div>
                    <div>
                        <van-field v-if="item.checkValue == '0'"
                                   v-model="item.question"
                                   label="问题描述"
                                   placeholder="请输入具体问题"
                                   maxlength="200"
                        />
                        <van-field v-if="item.checkValue == '0'"
                                   v-model="item.advice"
                                   label="整改意见"
                                   placeholder="请输入整改意见"
                                   maxlength="200"
                        />
                        <van-field v-if="item.checkValue == '0'"
                                   v-model="item.deadline"
                                   label="整改期限(天)"
                                   placeholder="请输入整改期限(1-60)"
                                   type="digit"
                                   maxlength="2"
                                   :formatter="NumFormatter"
                        />
                        <div  class="state-info" v-if="item.checkValue == '0'"> <van-cell> 图片上传</van-cell></div>
                        <div class="card-upload" v-if="item.checkValue == '0'">
                            <van-uploader v-model="item.fileList"
                                          accept="image"
                                          :max-count="1"
                                          :after-read="afterRead(item)"/>
                        </div>
                        <van-cell>
                        <van-checkbox v-if="item.checkValue == '0'"  icon-size="0.3rem" v-model="item.masterPointsChecked"    checked-color="#3F7C53">是否关联站长考核</van-checkbox>
                        </van-cell>
                        <van-field v-if="item.masterPointsChecked"
                                   v-model="item.masterPoints"
                                   label="站长关联扣分"
                                   placeholder="请输入站长扣分值(5-20)"
                                   type="digit"
                                   maxlength="2"
                                   :formatter="NumFormatter"
                        />
                    </div>
                </div>
                <div v-if="checkType===2" class="qc-content__title">运营规范</div>
                <div v-if="checkType===2" v-for="(item, index) in marketCheckingList" :key="index">
                    <div class="qc-content__item  flex">
                        <div class="qc-left">{{ item.dictValue }}</div>
                        <div class="qc-right">
                            <van-radio-group checked-color="#3F7C53"
                                             class="radiogroup"
                                             direction="horizontal"
                                             v-model="item.checkValue">
                                <van-radio name="1">规范
                                    <template #icon="props">
                                        <img class="img-icon" :src="props.checked ? checked : unchecked"/>
                                    </template>
                                </van-radio>
                                <van-radio name="0">不规范
                                    <template #icon="props">
                                        <img class="img-icon" :src="props.checked ? checked : unchecked"/>
                                    </template>
                                </van-radio>
                            </van-radio-group>
                        </div>
                    </div>
                    <div>
                        <van-field v-if="item.checkValue == '0'"
                                   v-model="item.question"
                                   label="问题描述"
                                   placeholder="请输入具体问题"
                                   maxlength="200"
                        />
                        <van-field v-if="item.checkValue == '0'"
                                   v-model="item.advice"
                                   label="整改意见"
                                   placeholder="请输入整改意见"
                                   maxlength="200"
                        />
                        <van-field v-if="item.checkValue == '0'"
                                   v-model="item.deadline"
                                   label="整改期限(天)"
                                   placeholder="请输入整改期限(1-60)"
                                   type="digit"
                                   maxlength="2"
                                   :formatter="NumFormatter"
                        />
                        <div  class="state-info" v-if="item.checkValue == '0'">图片上传</div>
                        <div class="card-upload" v-if="item.checkValue == '0'">
                            <van-uploader v-model="item.fileList"
                                          accept="image"
                                          :max-count="1"
                                          :after-read="afterRead(item)"/>
                        </div>
                    </div>
                </div>
                <div class="qc-content__title">其它情况</div>
                <van-field v-model="instructions"
                           label="非是否规范的其它情况"
                           placeholder="更换场所中、非达标建设中等具体情况说明记录"
                           maxlength="200"
                />
                <div v-if="checkType===1" class="qc-content__title">站点检查图片</div>
                <div v-if="checkType===1" class="card-upload" >
                    <van-uploader v-model="checkfileList"
                                  accept="image"
                                  :max-count="1"
                                  :after-read="afterRead2" :before-delete="deletePic" />
                </div>
            </div>


            <div class="nextBtn">
                <van-button class="publicBtnActive"
                            slot="button"
                            type="info"
                            v-if="isManager == '1'"
                            color="#3F7C53"
                            @click="submitClick">确认
                </van-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {saveCheckInfo, getMarketList, isCheckManager} from '@/utils/ruleChecking';
    import {selectStation} from '@/utils/putInto';
    import {getDictByPage, uploadZipAndAlreadyImg} from "../../utils/api";
    import common from '@/utils/common'
    import JsZip from "jszip";

    export default {
        name: "ruleChecking.vue",
        data() {
            return {
                uniqueNo: common.getItem('uniqueNo') ? common.getItem('uniqueNo') : this.$route.query.uniqueNo,
                isManager: '',//是否抽检人员
                instructions: '',
                showkindList: false,
                showMarketList: false,
                stationNo: '',//站点编号
                marketerNo: '',//运营编号
                deptNo: '',//部门编号
                typeText: '站点规范',
                stationCheckingList: [],
                marketCheckingList: [],
                fileImgUrls: [],
                fileImgUrls2: [],
                imgUrls: [],
                checkfileList: [],
                uploadZipImgList:[],
                fileImg:[],
                pathMap:  {},
                kindList: [],
                marketList: [],
                stationTxt: '',
                marketName: '',
                showState: false,
                typeList: [
                    {
                        text: '站点规范',
                        value: 1
                    },
                    {
                        text: '运营规范',
                        value: 2
                    }
                ],
                checkType: 1,
                pageIndex: 1,//页码
                pageSize: 10,
                infoList: [],
                stateText: '请选择',
                infoName: '',
                goodsLoading: false,
                goodsFinished: false,
                isLoadingGoods: false,
                addGoodShow: false,
                showPicker: false,
                showCalendar: false,
                columns: [],
                checked: require('@/assets/img/site/checked.png'),
                unchecked: require('@/assets/img/site/unchecked.png'),
                qc_default: require('@/assets/img/qc/qc_default.png'),
                qc_yes: require('@/assets/img/qc/qc_yes.png'),
                qc_no: require('@/assets/img/qc/qc_no.png'),
                logoImg: require('@/assets/img/siteManage/headerIcon.png'),
            }
        },
        created() {
            this.getCheckListPageInfo();
        },
        mounted() {
            this.isCheckManager();
        },
        methods: {
            onChange(item) {
                item.masterPoints="";
            },
            isCheckManager() {
                isCheckManager({uniqueNo: this.uniqueNo}).then(res => {
                    console.log("res:", res)
                    if (res.code === 200 && res.data.businessCode === 200) {
                        this.isManager = res.data.data;
                        console.log("this.isManager,", this.isManager)
                    }
                })
            },
            formatter(value) {
                // 过滤输入的数字
                return value.replace(/\./, '');
            },
            NumFormatter(val) {
                if (val.length == 1) {
                    return val.replace(/[^1-9]/g, "")
                } else {
                    return val.replace(/\D/g, "")
                }
            },
            afterRead(item) {
                return file => {
                    // 大于15M
                    if (file.file.size > 15 * 1024 * 1024) {
                        this.$toast('图片太大，请上传小于15M的图片')
                        return
                    }
                    let reader = new FileReader()
                    reader.readAsDataURL(file.file)
                    reader.onload = function (e) {
                        let content = file.content //图片的src，base64格式
                        let img = new Image()
                        let namearr = file.file.name.split('.')
                        let name = '.' + namearr[namearr.length - 1]
                        img.src = content
                        img.onload = function () {
                            common.compress(img, (dataURL) => {
                                var imgs = item.fileList || [];
                                var i = imgs.length;
                                if (i <= 0) {
                                    imgs = [{data: dataURL, name: name}];
                                } else {
                                    imgs[i - 1] = [{data: dataURL, name: name}];
                                }
                                item.fileList = imgs;
                                console.log("afterRead:", item.fileList)
                            })
                        }
                    }
                }
            },
            afterRead2 (file, detail) {
                // 大于15M
                if (file.file.size > 20 * 1024 * 1024) {
                    this.$toast('图片太大，请上传小于20M的图片')
                    return
                }
                if (!common.imageVerify(file.file.type)) {
                    this.checkfileList.splice(detail.index, 1)
                    this.$toast('上传图片必须为png或者jpg格式')
                    return
                }
                // 小于100K不进行压缩
                let _this = this
                if (file.file.size < 500 * 1024) {

                    let namearr = file.file.name.split('.')

                    let name = '.' + namearr[namearr.length - 1]

                    _this.uploadZipImgList.push({ data: file.content, name: name })
                    return
                }
                let reader = new FileReader()
                reader.readAsDataURL(file.file)

                reader.onload = function (e) {
                    let content = file.content //图片的src，base64格式
                    let img = new Image()
                    let namearr = file.file.name.split('.')
                    let name = '.' + namearr[namearr.length - 1]
                    img.src = content
                    img.onload = function () {

                        common.compress(img, (dataURL) => {
                            _this.uploadZipImgList.push({ data: dataURL, name: name })

                        })
                    }
                }
            },
            deletePic (file, pic) {
                this.checkfileList.splice(pic.index,1)
                if (file.url){
                    this.fileImg.splice(pic.index,1)
                }else {
                    this.uploadZipImgList.splice(pic.index - this.fileImg.length,1)
                }
            },

            getCheckListPageInfo() {
                getDictByPage('checkList').then(res => {
                    if (res.code === 200) {
                        let data
                        if (res.encryptedStatus === '1') {
                            data = JSON.parse(common.decrypt(res.data, res.timestamp));
                        } else {
                            data = res.data
                        }
                        if (data.businessCode === 200) {
                            this.stationCheckingList = JSON.parse(data.data).stationChecking
                            this.marketCheckingList = JSON.parse(data.data).marketChecking
                        }
                    }
                })
            },
            onConfirmName(value) {
                this.stationTxt = value.text === '请选择' ? '' : value.text;
                this.stationNo = value.stationNo;
                this.showkindList = false;
            },
            onConfirmUserName(value) {
                this.marketName = value.text === '请选择' ? '' : value.text;
                this.marketerNo = value.marketerNo;
                this.deptNo = value.deptNo;
                this.showMarketList = false;
            },
            changeStateName(value) {
                this.typeText = value.text;
                this.checkType = value.value;
                this.showState = false;
            },
            getStationList(name) {
                selectStation({stationName: name, isCheck: 1}).then(res => {
                    if (res.code === 200 && res.data.businessCode === 200) {
                        this.showkindList = true;
                        this.kindList = res.data.data.map(item => {
                            item.text = item.stationNameAndMasterName;
                            delete item.stationNameAndMasterName;
                            return item;
                        })
                        this.kindList.unshift({text: '请选择', stationNo: ''});
                    }
                })
            },
            getMarketList(name) {
                getMarketList({marketerName: name}).then(res => {
                    if (res.code === 200 && res.data.businessCode === 200) {
                        this.showMarketList = true;
                        this.marketList = res.data.data.map(item => {
                            item.text = item.nameAndDept;
                            delete item.nameAndDept;
                            return item;
                        })
                        this.showMarketList.unshift({text: '请选择', marketerNo: '', deptNo: ''});
                    }
                })
            },
            //关键字查询站点名称
            searchKeyword() {
                if (!this.stationTxt) {
                    this.$message.warning('请输入站点名称关键字');
                    return;
                }
                this.getStationList(this.stationTxt);
            },
            searchMarket() {
                if (!this.marketName) {
                    this.$message.warning('请输入运营人员姓名');
                    return;
                }
                this.getMarketList(this.marketName);
            },
            saveAction(map,checkMap) {
                console.log("开始保存")
                let params = {};
                params.checkType = this.checkType;
                params.uniqueNo = this.uniqueNo;
                params.instructions = this.instructions;
                params.filePathArr = map;
                params.checkfilePathArr = checkMap;
                if (this.checkType == 1) {
                    params.stationNo = this.stationNo;
                    params.CheckDetails = this.stationCheckingList;
                } else {
                    params.marketerNo = this.marketerNo;
                    params.deptNo = this.deptNo;
                    params.CheckDetails = this.marketCheckingList;
                }
                console.log('params: ', params)
                saveCheckInfo(params).then(res => {
                    if (res.code == 200) {
                        if (res.data && res.data.businessCode == 200) {
                            this.$toast("提交成功");
                            this.stationNo = '';
                            this.marketerNo = '';
                            this.deptNo = '';
                            this.stationTxt = '';
                            this.marketName = '';
                            this.instructions = '';
                            this.pathMap={};
                            this.checkfileList = [];
                            this.uploadZipImgList= [];
                            if (this.checkType == '1') {
                                for (var i = 0; i < this.stationCheckingList.length; i++) {
                                    this.stationCheckingList[i].checkValue = '';
                                    this.stationCheckingList[i].question = '';
                                    this.stationCheckingList[i].advice = '';
                                    this.stationCheckingList[i].deadline = '';
                                    this.stationCheckingList[i].masterPoints = '';
                                    this.stationCheckingList[i].fileList = [];
                                    this.stationCheckingList[i].masterPointsChecked = false;
                                }
                            }
                            if (this.checkType == '2') {
                                for (var i = 0; i < this.marketCheckingList.length; i++) {
                                    this.marketCheckingList[i].checkValue = '';
                                    this.marketCheckingList[i].question = '';
                                    this.marketCheckingList[i].advice = '';
                                    this.marketCheckingList[i].deadline = '';
                                    this.marketCheckingList[i].fileList = [];
                                }
                            }
                        }
                    }
                })
            },
            submitClick() {
                console.log("stationCheckingList", this.stationCheckingList)
                if (!this.checkType) {
                    this.$message.warning('请选择检查类型.');
                    return;
                }
                if (this.checkType == '1') {
                    if (!this.stationNo) {
                        this.$message.warning('请选择站点名称');
                        return;
                    }
                    var stationCount = 0;
                    for (let obj of this.stationCheckingList) {
                        if (!obj.checkValue) {
                            stationCount = stationCount + 1;
                        }
                        if (obj.checkValue == '0' && (!obj.question || !obj.advice || !obj.deadline)) {
                            this.$message.warning('如不符合规范，需填写具体问题、整改意见及整改期限.');
                            return;
                        }
                        if (obj.checkValue == '0' && obj.masterPointsChecked&&!obj.masterPoints) {
                            this.$message.warning('关联站长考核，请输入具体站长扣分.');
                            return;
                        }
                        if (obj.checkValue == '0' && obj.masterPointsChecked&&obj.masterPoints&&(parseInt(obj.masterPoints)<5 || parseInt(obj.masterPoints)>20)) {
                            this.$message.warning('站长扣分范围5-20，请结合问题正确填写.');
                            return;
                        }
                        if (obj.checkValue == '0' &&!obj.masterPointsChecked) {
                            obj.masterPoints="";
                        }
                    }
                    if ((this.stationCheckingList.length == stationCount) && (!this.instructions)) {
                        this.$message.warning('请至少抽查一个规范问题或做其他情况说明');
                        return;
                    }
                    if(this.checkType=='1' && this.checkfileList.length==0){
                        this.$message.warning('请上传站点检查照片');
                        return;
                    }

                    let self = this;
                    let imgName = 'ruleCheck'
                    var zip = new JsZip();
                    var img = zip.folder("images");
                    for (let obj of this.stationCheckingList) {
                        console.log('obj.fileList:', obj.fileList)
                        if (obj.fileList) {
                            console.log('有图片上传')
                            var list = obj.fileList[0];
                            console.log('list:', list)
                            // console.log("mingzi:",obj.fileList[0].name)
                            for (var i = 0; i < list.length; i++) {
                                img.file(imgName + '_' + obj.dictKey + list[i].name, list[i].data.substring(list[i].data.indexOf(",") + 1), {base64: true});
                            }
                        }
                    }
                    let json = {}
                    json[imgName] = self.fileImgUrls
                    let fileAleradyJson = JSON.stringify(json)
                    console.log("提交的网络图片：", json)
                    zip.generateAsync({type: "blob"}).then(function (content) {
                        uploadZipAndAlreadyImg(content, "1", fileAleradyJson).then(res => {
                            if (res.code == 200 && (res.data.businessCode === 200)) {
                                console.log("返回：", res.data)
                                console.log("arr",res.data.resultMap)
                                let map= res.data.resultMap;
                                self.fileImgUrls =  res.data[imgName]
                                //console.log("map：", map);
                                console.log('this.uploadZipImgList:', self.uploadZipImgList)
                                    let imgName = 'stationCheck'
                                    var zip2 = new JsZip();
                                    var img2 = zip2.folder("images");
                                     img2.file(imgName + '_' + 0 + self.uploadZipImgList[0].name,
                                         self.uploadZipImgList[0].data.substring(self.uploadZipImgList[0].data.indexOf(",") + 1), {base64: true});

                                    let json = {}
                                    json[imgName] = self.fileImgUrls2
                                    let fileAleradyJson2 = JSON.stringify(json)
                                    console.log("提交站点检查图片：", json)
                                    zip2.generateAsync({type: "blob"}).then(function (content) {
                                        uploadZipAndAlreadyImg(content, "1", fileAleradyJson2).then(res => {
                                            if (res.code == 200 && (res.data.businessCode === 200)) {
                                                console.log("返回：", res.data)
                                                console.log("arr",res.data.resultMap)
                                                self.saveAction(map,res.data.resultMap);
                                            }
                                        }).catch(() => {
                                        })

                                    }, e => {
                                        this.$toast('上传图片异常')
                                    });


                                //self.saveAction(map);
                            }
                        }).catch(() => {
                        })

                    }, e => {
                        this.$toast('上传图片异常')
                    });
                }

                if (this.checkType == '2') {
                    if (!this.marketerNo) {
                        this.$message.warning('请选择运营人员');
                        return;
                    }
                    var marketCount = 0;
                    for (let obj of this.marketCheckingList) {
                        if (!obj.checkValue) {
                            marketCount = marketCount + 1;
                        }
                        if (obj.checkValue == '0' && (!obj.question || !obj.advice || !obj.deadline)) {
                            this.$message.warning('如不符合规范，需填写具体问题、整改意见及整改期限.');
                            return;
                        }
                    }
                    if ((this.marketCheckingList.length == stationCount) && (!this.instructions)) {
                        this.$message.warning('请至少抽查一个规范问题或做其他情况说明');
                        return;
                    }
                    let self = this;
                    let imgName = 'ruleCheck'
                    var zip = new JsZip();
                    var img = zip.folder("images");
                    for (let obj of this.marketCheckingList) {
                        console.log('obj.fileList:', obj.fileList)
                        if (obj.fileList) {
                            console.log('有图片上传')
                            var list = obj.fileList[0];
                            console.log('list:', list)
                            // console.log("mingzi:",obj.fileList[0].name)
                            for (var i = 0; i < list.length; i++) {
                                img.file(imgName + '_' + obj.dictKey + list[i].name, list[i].data.substring(list[i].data.indexOf(",") + 1), {base64: true});
                            }
                        }
                    }
                    let json = {}
                    json[imgName] = self.fileImgUrls
                    let fileAleradyJson = JSON.stringify(json)
                    console.log("提交的网络图片：", json)
                    zip.generateAsync({type: "blob"}).then(function (content) {
                        uploadZipAndAlreadyImg(content, "1", fileAleradyJson).then(res => {
                            if (res.code == 200 && (res.data.businessCode === 200)) {
                                console.log("返回：", res.data)
                                console.log("arr",res.data.resultMap)
                                let map= res.data.resultMap;
                                console.log("map：", map);
                                self.saveAction(map,null);
                            }
                        }).catch(() => {
                        })

                    }, e => {
                        this.$toast('上传图片异常')
                    });
                }


            },

        }
    }
</script>

<style lang="less">
    @import "../../style/putInto.less";

    .van-tab {
        font-size: 0.35rem;
    }

    .van-cell {
        font-size: 12px;
    }

    .nativeRadio .van-radio {
        margin-left: 10px;
    }

    .search-box {
        .van-cell {
            padding: 14px 15px;
            border-bottom: 1px solid #F7F8F9;
        }
    }

    .radiogroup {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .qc-info {
        background: #f7f8f9;
        padding-bottom: 2.5rem;
        height: 100%;

        .desc {
            padding: 0.3rem;
            color: #999;
            font-size: 0.24rem;
            // background: #f7f8f9;
            letter-spacing: 0.02rem;
        }

        .flex {
            display: flex;
        }

        .line15 {
            height: 0.1rem;
        }

        .van-radio--horizontal {
            margin-right: 0.26rem;

            &:nth-child(2) {
                margin-right: 0;
            }
        }

        .van-radio__label {
            margin-left: 0.08rem !important;
        }

        .img-icon {
            width: 0.28rem;
            height: 0.28rem;


            &:nth-child(1) {
                margin-left: 0;
            }
        }

        .qc-content {
            background: #fff;
            margin-bottom: 0.15rem;
        }
        .state-info{
            font-size: 13px;
            font-weight: 200;
            color: #7E8185;
        }
        .qc-content__title {
            text-align: left;
            background: #ffffff;
            height: 0.8rem;
            line-height: 0.8rem;
            padding: 0 0.3rem;
            color: #333333;
            font-weight: bold;
            font-size: 15px;
            margin-top: 0.1rem;

            &:before {
                width: 8px;
                height: 5px;
                color: #3f7c53;
                content: "| ";
            }
        }

        .qc-content__item {
            justify-content: space-between;
            padding: 0.2rem 0.3rem;
            align-items: center;
            border-bottom: 0.01rem solid #eee;

            .qc-left {
                width: 5.8rem;
                font-size: 0.2rem;
            }

            .qc-right {
                width: 2.8rem;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                font-size: 0.2rem;
            }
        }

        .site-item {
            padding: 0.2rem 0.2rem;

            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            background: #fff;

            .site-item-cell {
                margin: 0.2rem 0;
                padding: 0.2rem;
            }

            .VDS-ShadowBg_home {
                border-radius: 8px;
                box-shadow: 0px 2.5px 5px 5px rgba(0, 0, 0, 0.04);
            }

            .logo-img {
                width: 68px;
                height: 68px;
            }

            .site-item_right {
                margin-left: 0.2rem;
                flex-direction: column;
                justify-content: space-between;

                .site-item_one {
                    font-size: 15px;
                    color: #333333;
                    font-weight: bold;
                }

                .site-item_two {
                    // margin-top: -0.1rem;
                    font-weight: 13px;
                    color: #999999;
                    margin: 3px 0;
                }

                .site-item_three {
                    div {
                        border: 0.01rem solid #3f7c53;
                        padding: 0 8px;
                        margin-right: 0.1rem;
                        font-size: 12px;
                        color: #3f7c53;
                        border-radius: 4px;
                    }
                }
            }
        }

        .card-upload {
            // margin: 0 0.3rem;
            height: 1.6rem;
            background: #fff;
            display: flex;
            align-items: center;
            padding: 0 0.2rem;
            overflow: hidden;
        }

        .nextBtn {
            width: 100%;
            padding: 12px;
            background: #fff;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
    .checkboxitem {
        background: #fff;
        height: 0.4rem;
        line-height: 0.4rem;
        padding: 0.2rem 0.32rem;
    }
</style>
