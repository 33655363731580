import axios from './request'
import {businessApi, sign} from "./api";

export var saveCheckInfo = (params) => {
    return axios({
        url: `${businessApi}/check/saveCheckInfo`,
        method: 'POST',
        data:sign(params)
    })
}

export var saveFeedback = (params) => {
    return axios({
        url: `${businessApi}/check/saveFeedback`,
        method: 'POST',
        data:sign(params)
    })
}

export var getMarketList = (params) => {
    return axios({
        url: `${businessApi}/marketer/getMarketList`,
        method: 'POST',
        data: sign(params)
    })
}

export var getCheckList = (params) => {
    return axios({
        url: `${businessApi}/check/getCheckList`,
        method: 'POST',
        data: sign(params)
    })
}

export var getQuestionList = (params) => {
    return axios({
        url: `${businessApi}/check/getQuestionList`,
        method: 'POST',
        data: sign(params)
    })
}

export var isCheckManager = (params) => {
    return axios({
        url: `${businessApi}/check/isCheckManager`,
        method: 'POST',
        data: sign(params)
    })
}